import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_set_access = _resolveComponent("set-access")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_dialog_shell, {
      width: "950px",
      title: _ctx.$wordList.ProperAllTextNewAccessGroup,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.emit('close'))),
      onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.commit++))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_set_access, {
          commit: _ctx.commit,
          onSuccess: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emit('success')))
        }, null, 8, ["commit"])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}