
import dialogShell from '@/components/common/dialog-shell/index.vue';
import {
    defineComponent, ref
} from 'vue';
import setAccess from '@/components/view/pm/set-access';

export default defineComponent({
    components: {
        dialogShell,
        setAccess
    },
    props: {},
    setup(props, { emit }) {
        const commit = ref(0);

        return {
            commit,
            emit
        };
    }
});

